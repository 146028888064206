"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.courseSystemStatusValName = exports.courseSystemStatusVal = void 0;
exports.courseSystemStatusVal = {
    IN_USE: 1,
    STOPPED: 0
};
exports.courseSystemStatusValName = {
    1: '使用中',
    0: '已停用'
};
