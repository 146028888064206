"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.homePageMenuLayoutValName = exports.homePageMenuLayoutVal = exports.loginPageLayoutValName = exports.loginPageLayoutVal = void 0;
exports.loginPageLayoutVal = {
    CENTER: 1,
    LEFT: 2,
    RIGHT: 3
};
exports.loginPageLayoutValName = {
    1: '居中',
    2: '靠左',
    3: '靠右'
};
exports.homePageMenuLayoutVal = {
    TOP: 1,
    LEFT: 2
};
exports.homePageMenuLayoutValName = {
    1: '顶栏',
    2: '侧边栏'
};
