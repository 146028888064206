"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemCategoryTypeValName = exports.systemCategoryTypeVal = void 0;
exports.systemCategoryTypeVal = {
    COURSE: 0,
    TEACHING: 1,
    TRAINING: 2
};
exports.systemCategoryTypeValName = {
    0: '课程系统',
    1: '教学系统',
    2: '实训系统'
};
